<template>
  <div id="app">
    <div :class="topDivClass">
      <the-navbar></the-navbar>
      <!-- <transition 
      name="custom-classes-transition"
      enter-active-class="animated fadeIn fast"
      leave-active-class="animated fadeOut fast"> -->
      <router-view/>
      <!-- </transition> -->
    </div>
  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import api from '@/services/api'
import { displayMessages } from '@/components/need_form/utils'

export default {
  components: {
    TheNavbar
  },
  created () {
    this.loadUser().then(() => {
      this.loadGeneralData()
      if (this.user_is_staff) {
        this.loadUsernames() // used for 'admin login as'
      }
    })
    var localeCookie = this.$cookies.get('django_language')
    if (localeCookie) {
      this.$language.current = localeCookie
    }
    api.get('/django_messages/').then(
      function (resp) {
        displayMessages(resp.data)
      })
  },
  methods: {
    ...mapActions('needForm', [
      'getItfDatas'
    ]),
    ...mapActions([
      'getNeedStatusChoices',
      'loadUser',
      'loadUsernames',
      'loadAPIUrls'
    ]),
    loadGeneralData () {
      this.loadAPIUrls().then(() => {
        if (this.loggedIn) {
          this.getNeedStatusChoices()
        }
        this.getItfDatas()
      })
    }
  },
  computed: {
    ...mapState(['topDivClass', 'user']),
    ...mapGetters(['loggedIn', 'user_is_staff']),
  },
  watch: {
    '$language.current': function () {
      this.loadGeneralData() // Reload for i18n
    },
    user: function () {
      if (this.$store.state.needList.needs_loaded) {
        this.$store.dispatch('needList/loadData') // Admin login as -> reload filtered list
      }
    }
  }
}
</script>

<style lang="scss">
@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-vue/src/index.scss';
@import 'vue2-animate/src/sass/vue2-animate.scss';
@import 'toastr/toastr.scss';
</style>
<style>
@import 'assets/style.css'
</style>