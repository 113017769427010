import Vue from 'vue'
import Vuex from 'vuex'
import needForm from './modules/need_form'
import needDetail from './modules/need_detail'
import needList from './modules/need_list'
import api from '@/services/api'
import axios from 'axios'
var _ = require('lodash')

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    needForm,
    needDetail,
    needList
  },
  state: {
    user: null,
    non_staff_usernames: [],
    topDivClass: null,
    need_status_choices: null,
    api_urls: {}
  },
  actions: {
    getNeedStatusChoices: function({ state }) {
      api.get(state.api_urls.json_need_status_choices)
      .then(function (resp) {
        state.need_status_choices = resp.data
      })
    },
    loadUser: function({commit, state}) {
      state.user = axios({ url: `/accounts/me/`, baseURL: '/' })
        .then(response => {
          commit('setUser', response.data)
          return response.data
        }).catch(e => {
          console.log(e)
        })
      return state.user
    },
    loadUsernames: function({commit, state}) {
      state.users = axios({ url: `/accounts/usernames/`, baseURL: '/' })
        .then(response => {
          commit('setUsernames', response.data)
          return response.data
        }).catch(e => {
          console.log(e)
        })
      return state.users
    },
    loadAPIUrls: function ({commit}) {
      return axios.get(`/api/urls/`)
        .then(response => {
          commit('setUrls', response.data)
        }).catch(e => {
          console.log(e)
        })
    }
  },
  mutations: {
    setUser (state, data) {
      state.user = data
    },
    setUsernames (state, data) {
      state.non_staff_usernames = data
    },
    setUrls (state, data) {
      state.api_urls = data
    }
  },
  getters: {
    user_is_staff: function (state, getters) {
      return getters.loggedIn && state.user.is_staff
    },
    user_is_client: function (state, getters) {
      return getters.loggedIn && state.user.is_client
    },
    needStatusDict: function (state) {
      return _.fromPairs(state.need_status_choices)
    },
    needStatusDisplay: function (state, getters) {
      return (status) => {
        return getters.needStatusDict[status]
      }
    },
    loggedIn: function (state) {
      return state.user && state.user.logged_in
    }
  }
})
